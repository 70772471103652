import { useNavigate } from 'react-router-dom';
import './InfoService.scss'
import { CUSBoxItem, CUSBoxTile } from "component/CUSComponent"
import { useEffect, useState } from 'react';
import Footer from 'component/layout/footer/Footer';

const InfoService = (props) => {
    const navigate = useNavigate();
    const [contents, setContents] = useState([]) // 정보서비스 컨텐츠 리스트

    // 최초진입시 컨텐츠 목록 조회
    useEffect(() => {
        getContents()
    }, [])

    // 컨텐츠 목록 조회 호출
    const getContents = async() => {
        try {
            const contents = await props.util.callApi.Get('/content', {})
            if(!!contents.data?.status) {
                return
            }

            setContents(contents.data)
        } catch(error) {
            console.error(error)
        }
    }
    /* 
        RPA 컨텐츠로 이동 함수. /content/'컨텐츠시퀀스'로 이동하여 
        '컨텐츠시퀀스'를 파라미터로 조회
    */
    const moveContent = (contentSeq) => {
        if(!!contentSeq) {
            navigate(`/info-service/content/${contentSeq}`)
        }
    }

    return (
        <article className='infoService'>
            <div className="intro" >
                <strong className='title'> 정보 서비스 </strong>
                <p className='description'>
                    로봇이 생산하는 양질의 공공정보 생활정보를 구독하세요.<br/>
                    무료로 매일매일 이메일로 발송합니다.
                </p>
            </div>
            <CUSBoxTile>
                {
                    contents.map((e, i) => (
                        <CUSBoxItem 
                            key={`contents${e.contentSeq}`} 
                            title={e.contentNm} 
                            thumbnailImg={e.contentThumbnailImg}
                            description={e.contentSimpleDescription} 
                            onClick={(evt) => {moveContent(e.contentSeq)}}
                        />
                    ))
                }
            </CUSBoxTile>
            <div class="item-area offer-board"/>
            <Footer />
        </article>
    )
}

export default InfoService