import Footer from 'component/layout/footer/Footer'
import './Home.scss'
import { useNavigate } from 'react-router-dom'
import Public from 'static/images/main_public.webp'
import Life from 'static/images/main_life.webp'
import Finance from 'static/images/main_finance.webp'
import Left from 'static/images/move-left.png'
import Right from 'static/images/move-right.png'
import ScrollDown from 'static/images/scroll_info.webp'
import CloseIco from 'static/images/close.png'
import { useEffect, useRef, useState } from 'react'
import useScrollFadeIn from 'component/hooks/useScrollFadeIn'
import Constants from '../offerBoard/Constants/Constants'
import DOMPurify from 'dompurify'
import ReactQuill from 'react-quill'

const Home = (props) => {
    const navigate = useNavigate()
    const mobileImgRef = useRef(null) // 정보서비스 설명 사진 모바일용 ref
    // const animatedItem1 = useScrollFadeIn('right', 1, 0);
    // const animatedItem2 = useScrollFadeIn('right', 1, 0);
    // const animatedItem3 = useScrollFadeIn('up', 1, 0);
    const [offerRanking, setOfferRanking] = useState([]) // 기능제안 상위10개 리스트
    const [selectBoard, setSelectBoard ] = useState(false) // 기능제안 게시판 상세보기 다이얼로그 오픈
    const [offerDetail, setOfferDetail] = useState({ // 기능제안 게시판 상세보기 정보
        boardSeq: '',
        boardTitle: '',
        boardWriterNm: '',
        dt: '',
        boardContent: '',
        boardGoodCnt: 0,
        boardBadCnt: 0,
        replyCnt: 0
    })
    
    useEffect(() => {
        window.scrollTo({top:0}) // 화면 진입시 스크롤 상단으로
        getOfferRanking() // 기능제안 상위10개 조회
    },[])

    // 기능제안 상위10개 조회
    const getOfferRanking = async() => {
        try {
            const result = await props.util.callApi.Get('/board/offer/ranking')
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const offerRanking = result.data
            setOfferRanking(offerRanking)
        } catch(error) {
            console.error(error)
        }
    }

    // BOT Service 설명 목록
    const botSerivceList = [
        <div key='publicData' className='bot-service-info-card' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url(${Public})`}}>
            <p>공공데이터</p>
            <p>{'서울시 공고\n나라장터 입찰공고 ...'}</p>
            {
                props.isMobile &&
                <>
                <img alt='>' src={Right} className='right-btn' onClick={(e) => {moveInfoServiceImg('forward', e)}}></img>
                </>
            }
        </div>,
        <div key='lifeInfo' className='bot-service-info-card' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url(${Life})`}}>
            <p>생활정보</p>
            <p>{'음원 탑10\n도서 베스트셀러 정보\n박스오피스 정보 ...'}</p>
            {
                props.isMobile &&
                <>
                <img alt='<' src={Left} className='left-btn' onClick={(e) => {moveInfoServiceImg('prev',e)}}></img>
                <img alt='>' src={Right} className='right-btn' onClick={(e) => {moveInfoServiceImg('forward',e)}}></img>
                </>
            }
        </div>,
        <div key='financeInfo' className='bot-service-info-card' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url(${Finance})`}}>
            <p>증권정보</p>
            <p>{'기업 증권정보\n증권주요시세 정보\n시장지표 ...'}</p>
            {
                props.isMobile &&
                <>
                <img alt='<' src={Left} className='left-btn' onClick={(e) => {moveInfoServiceImg('prev',e)}}></img>
                </>
            }
        </div>
    ]

    // 모바일 시 BOT Service 화살표 클릭시 이미지 이동
    const moveInfoServiceImg = (direction,e) => {
        const scrollLeft = mobileImgRef.current.scrollLeft
        if(direction === 'prev') {
            mobileImgRef.current.scrollTo({left: scrollLeft - 175, behavior: 'smooth'})
        }
        else if(direction === 'forward') {
            mobileImgRef.current.scrollTo({left: scrollLeft + 175, behavior: 'smooth'})
        }
    }

    // 제안게시판 디테일 조회
    const getOfferDetail = async(boardSeq) => {
        try {
            const result = await props.util.callApi.Get(`/board/offer/${boardSeq}`)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            const offerDetail = result.data.offerDetail
            setOfferDetail(offerDetail)
        } catch(error) {
            console.error(error)
        }
    }

    // 제안게시판 디테일 조회 다이얼로그 닫기
    const closeOfferDlg = (e) => {
        e.preventDefault(); 
        setSelectBoard(false)
        setOfferDetail({
            boardSeq: '',
            boardTitle: '',
            boardWriterNm: '',
            dt: '',
            boardContent: '',
            boardGoodCnt: 0,
            boardBadCnt: 0,
            replyCnt: 0
        })
    }
    // 제안게시판 상세 페이지 이동
    const moveDetail = (e, seq) => {
        e.preventDefault();
        navigate(`/offer-board/content/${seq}`)
    }

    return (
        <article className='home-page'>
            {/* 최상단 소개 Item */} 
            {/*
            <div className='item-area information' >
                <div className='item information'>
                    {
                        //<strong>BOT SERVICE를 통한 업무의 <span style={{color:'#008bff'}}>자동화</span></strong>
                        props.isMobile ?
                        <>
                        <p className='main-text-mobile'>{'BOT Service는 지능형 자동화 로봇서비스이며 누구나 사용할 수 있습니다.'}</p>
                        <p className='sub-text'>{'• 로봇이 생산한 정보를 무료로 구독\n• 나의 사무업무를 로봇이 대신하는 업무자동화 사용\n\n로봇과 함께 편리하게 일하세요.'}</p>
                        </>
                        :
                        <>
                        <p className='main-text'>{'BOT Service는 지능형 자동화 로봇서비스이며 누구나 사용할 수 있습니다.'}</p>
                        <p className='sub-text'>{'• 로봇이 생산한 정보를 무료로 구독\n• 나의 사무업무를 로봇이 대신하는 업무자동화 사용\n\n로봇과 함께 편리하게 일하세요.'}</p>
                        </>
                        // <p ref={mainTextRef}></p>
                        // <>
                        // <p className='type-test one'>BOT Service는 지능형 자동화 로봇서비스이며 누구나 사용할 수 있습니다.</p>
                        // <p className='type-test two'>로봇이 생산한 정보를 무료로 구독하거나, 업무자동화 서비스를 사용하여 당신의 업무를 자동화 할 수 있습니다.</p>
                        // </>
                    }
                    <img className='scroll-img' alt='scroll' src={ScrollDown} />
                </div>
            </div>
            */}
            {/* BOT Service */}
            <div className='item-area info-service'>
                <div className='item' style={{marginBottom: '10rem'}}>
                    {/* <strong className='title' style={{fontSize: '2.1rem', marginLeft:'2rem'}}>RPA 서비스를 손쉽게 받아보세요</strong> */}
                    {/* <div className='card' {...animatedItem1}> */}
                    <div className='card' >
                        <div className='title-area'>
                            <p className='title'>정보 서비스</p>
                            <strong className='description'>{'로봇이 매일매일 생산하는 양질의 공공정보 생활정보를 무료로 제공합니다'}</strong>
                        </div>
                        <div className='main'>
                            {
                                props.isMobile ?
                                <div className='mobile-scroll' ref={mobileImgRef}>
                                {botSerivceList}
                                </div>
                                :
                                botSerivceList
                            }
                        </div>
                        <div className='btn'>
                            <button onClick={(e) => {navigate('/info-service')}}>사용해보기</button>
                        </div>
                    </div>

                    {/* <div className='card' {...animatedItem2}> */}
                    <div className='card'>
                        <div className='title-area'>
                            <p className='title'>업무 자동화</p>
                            <strong className='description'>{'로봇이 당신의 업무를 대신하거나 도와줍니다. \n개인, 소상공인의 공통적으로 하는 반복작업, 회계업무 등 미리개발된 자동화 로봇서비스를 사용하세요.'}</strong>
                        </div>
                        <div className='main'>
                        </div>
                        <div className='btn'>
                            <button onClick={(e) => {navigate('/work-automation')}}>사용해보기</button>
                        </div>
                    </div>

                    {/* <div className='card'>
                        <div className='title-area'>
                            <p className='title'>Robot 공유</p>
                            <strong className='description'>{'당신의 업무를 자동화하고 싶다면 Robot 공유를 사용하세요. \nRPA 인프라를 사용할 수 있고, 업무 자동화개발 서비스를 제공합니다.'}</strong>
                        </div>
                        <div className='main'>
                        </div>
                        <div className='btn' >
                            <button className='disable' onClick={(e) => {navigate('#')}}>준비중</button>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* <div className='item-area offer-board' {...animatedItem3}> */}
            <div className='item-area offer-board'>
                <div className='item offer-board'>
                    <p className='title'>{`자동화하고 싶은 기능을 제안하세요\n인기 있는 제안들입니다`}</p>
                    <div className={`board-area`}>
                        {
                        offerRanking.length > 0 ?
                            props.isMobile ? 
                            <table className='board'>
                                <colgroup>
                                    <col />
                                    <col width={50}/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style={{textAlign: 'left'}}>제목</th>
                                        <th>추천</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        offerRanking.map((e,i) => (
                                            <tr key={`ranking${i}`} onClick={(evt) => {setSelectBoard(true); getOfferDetail(e.boardSeq)}}>
                                                <td className='title'>{`[${Constants.getTypeLabel(e.boardType)}]${e.boardTitle}`}</td>
                                                <td className='good'>{e.boardGoodCnt}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            :
                            <table className='board'>
                                <colgroup>
                                    <col width={100}/>
                                    <col />
                                    <col width={80}/>
                                    {/* <col width={80}/> */}
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>기능</th>
                                        <th style={{textAlign: 'left'}}>제목</th>
                                        <th>추천</th>
                                        {/* <th>비추천</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        offerRanking.map((e,i) => (
                                            <tr key={`ranking${i}`} onClick={(evt) => {setSelectBoard(true); getOfferDetail(e.boardSeq)}}>
                                                <td>{Constants.getTypeLabel(e.boardType)}</td>
                                                <td className='title'>{e.boardTitle}</td>
                                                <td className='good'>{e.boardGoodCnt}</td>
                                                {/* <td className='bad'>{e.boardBadCnt}</td> */}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            :
                            <div className='ranking-none'>
                                <p>랭킹 집계 중입니다</p>
                            </div>
                        }
                        <div className='btn'>
                            <button onClick={(e) => {navigate('/offer-board')}}>전체보기</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                !!selectBoard &&
                <div className='board-detail-dlg'>
                    {/* <div className='background'  onClick={(e) => {setSelectBoard(false)}}>
                    </div> */}
                    <div className='detail-area'>
                        {/* <a className='close' href='#!' onClick={(e) => {closeOfferDlg(e)}}>X</a> */}
                        <img className='close' src={CloseIco} alt='X' onClick={(e) => {closeOfferDlg(e)}}/>
                        <div className='detail'>
                            <div className='title'>
                                <p>{`[${Constants.getTypeLabel(offerDetail.boardType)}] ${offerDetail.boardTitle}`}</p>
                                <div className='writer'>
                                    <p>{offerDetail.boardWriterNm}</p>
                                    <p className='dt'>{offerDetail.dt}</p>
                                </div>
                            </div>
                            {/* <div className='text' > */}
                            <div className='text' >
                                {/* dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(offerDetail.boardContent),
                                }}> */}
                                <ReactQuill 
                                    className={'read-editer-style home'}
                                    theme="bubble" 
                                    value={offerDetail.boardContent} 
                                    modules={
                                        {
                                            clipboard: {
                                        matchVisual: false
                                    }}}
                                    readOnly={true} />
                            </div>
                        </div>
                        <button onClick={(e) => {moveDetail(e, offerDetail.boardSeq)}}>원글보기</button>
                    </div>
                </div>
            }
            <Footer />
        </article>
    )
}

export default Home