import { CUSTextField } from 'component/CUSComponent'
import './ModifyUser.scss'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie';
import { SHA256 } from 'crypto-js';

const ModifyUser = (props) => {
    const cookies = new Cookies();
    const navigate = useNavigate()
    // 회원정보 수정 값
    const [userInfo, setUserInfo] = useState({
        userCoNm: '',
        userJobs: '',
        phoneNum: ''
    })
    // 비밀번호 수정 값
    const [pwInfo, setPwInfo] = useState({
        originPw: '',
        newPw: '',
        newPwRe: ''
    })
    const [pwCk, setPwCk] = useState(true) // 새비밀번호, 재입력 같은지 검증 값

    // 페이지 진입시 유저 정보 가져오기
    useEffect(() => {
        getUserInfo()
    },[])
    // 유저 정보 가져오기
    const getUserInfo = async() => {
        const userInfo = await props.util.callApi.Get('/user')
            setUserInfo({
                userCoNm: userInfo.data.userCoNm, // 회사명
                userJobs: userInfo.data.userJobs, // 직종
                phoneNum: autoHypenPhone(userInfo.data.phoneNum) // 전화번호
            })
    }
    // 비밀변호변경에서 새비밀번호, 재입력 같은지 검증
    useEffect(() => {
        if(!!pwInfo.newPw && !!pwInfo.newPwRe) {
            if(pwInfo.newPw !== pwInfo.newPwRe) {
                setPwCk(false)
                return;
            }
        }
        setPwCk(true)
    }, [pwInfo.newPw, pwInfo.newPwRe])

    // 회원정보수정 폼 값 수정
    const changeUserInfo = (value, key) => {
        let temp = {...userInfo}
        temp[key] = value;
        setUserInfo(temp)
    }
    // 회원정보수정 
    const modifyUserInfo = async(e) => {
        e.preventDefault()
        try {
            const param = {
                userCoNm: userInfo.userCoNm,
                userJobs: userInfo.userJobs,
                phoneNum: userInfo.phoneNum.replaceAll('-', '')
            }
            const result = await props.util.callApi.Put('/user', param)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            // window.alert('회원정보가 수정되었습니다.')
            window.location.reload()
        } catch(error) {
            console.error(error)
        }
    }
    // 핸드폰 자동 하이픈
    const autoHypenPhone = (str) =>{
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';
        if( str.length < 4){
            return str;
        }else if(str.length < 7){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            return tmp;
        }else if(str.length < 11){
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            return tmp;
        }else{              
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            return tmp;
        }
    }

    // 비밀번호변경 폼 값 수정
    const changePwInfo = (value, key) => {
        let temp = {...pwInfo}
        temp[key] = value;
        setPwInfo(temp)
    }
    // 비밀번호변경 
    const changePw = async(e) => {
        e.preventDefault()
        if(!pwCk) {
            return
        }
        let reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/

        if(!reg.test(pwInfo.newPw)) {
            alert('비밀번호 규칙이 맞지 않습니다')
            return
        }

        try {
            const param = {
                oldUserPw: SHA256(pwInfo.originPw).toString(), // 기존 비빌번호
                newUserPw: SHA256(pwInfo.newPw).toString(), // 새 비밀번호
            }
            const result = await props.util.callApi.Put('/user/password', param)
            if(!!result.data?.status) {
                window.alert(result.data.errorMessage || '오류가 발생하였습니다.')
                return;
            }
            window.alert('비밀번호가 변경되었습니다.\n다시 로그인 해주세요.')
            cookies.remove('sessionUser', {path: '/'})
            navigate('/', {replace: true})

        } catch(error) {
            console.error(error)
        }
    }

    return (
        <article className='modify-page'>
            <div className='modify-area'>
                <div className='title-area'>
                    <p className='title'>회원 정보 수정</p>
                </div>
                <form id='userInfo' onSubmit={(e) => {modifyUserInfo(e)}} className='modify-table'>
                    <label required htmlFor='userCoNm'>회사명</label>
                    <CUSTextField 
                        id='userCoNm'
                        placeholder='회사명'
                        required
                        value={userInfo.userCoNm}
                        onChange={(e) => {
                            changeUserInfo(e.target.value, 'userCoNm')
                        }}
                        />
                    <label htmlFor='userJobs'>직종</label>
                    <CUSTextField 
                        id='userJobs'
                        placeholder='직종'
                        required
                        value={userInfo.userJobs}
                        onChange={(e) => {
                            changeUserInfo(e.target.value, 'userJobs')
                        }}
                        />
                    <label htmlFor='phoneNum'>전화번호</label>
                    <CUSTextField 
                        id='phoneNum'
                        placeholder='전화번호'
                        type='tel'
                        required
                        value={userInfo.phoneNum}
                        maxLength={13}
                        onChange={(e) => {
                            e.target.value = autoHypenPhone(e.target.value)
                            changeUserInfo(e.target.value, 'phoneNum')
                        }}
                        />
                </form>
                <div className='modify-btn-area'>
                    <button type='submit' form='userInfo' className='modify-btn'>수정</button>
                </div>

                <div className='title-area'>
                    <p className='title'>비밀번호 변경</p>
                    <p className='description'>* 영문 숫자 특수기호 조합 8~16자리</p>
                </div>
                <form id='changePw' onSubmit={(e) => {changePw(e)}} className='modify-table'>
                    <CUSTextField 
                        required 
                        type='password'
                        placeholder='기존 비밀번호'
                        value={pwInfo.originPw}
                        onChange={(e) => {
                            changePwInfo(e.target.value, 'originPw')
                        }}/>
                    <CUSTextField 
                        required 
                        type='password'
                        placeholder='새 비밀번호'
                        value={pwInfo.newPw}
                        onChange={(e) => {
                            changePwInfo(e.target.value, 'newPw')
                        }}/>
                    <CUSTextField 
                        required 
                        type='password'
                        placeholder='새 비밀번호 확인'
                        value={pwInfo.newPwRe}
                        style={!pwCk ? {border: '2px solid red'} : {}}
                        onChange={(e) => {
                            changePwInfo(e.target.value, 'newPwRe')
                        }}/>
                </form>
                <div className='modify-btn-area'>
                    <button type='submit' form='changePw' className='modify-btn'>비밀번호 변경</button>
                </div>
                
            </div>
        </article>
    )
}

export default ModifyUser