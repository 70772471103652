import { useNavigate, useParams } from 'react-router-dom';
import './Content.scss'
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import NormalImg from 'static/images/image_error.webp'
import ContentData from "./data/ContentDetailData.json"
import Footer from 'component/layout/footer/Footer';


const Content = (props) => {

    const navigate = useNavigate()
    const [cookies] = useCookies(['sessionUser']); // 쿠키 관리 Hook
    const params = useParams(); // URL에 담겨진 파라미터 꺼내는 Hook
    // 컨텐츠 정보
    const [contentInfo, setContentInfo] = useState({
        contentSeq: '',
        contentVideo: '',
        contentNm: '',
        contentDetailDescription: '',
        contentExplain: '',
        contentExplainImg: '',
        contentRes: '',
        contentResImg: '',
        contentKeywordYn: 'N',
        contentKeywordDescription: ''
    })

    const [keyword, setKeyword] = useState('') // 키워드
    const [email, setEmail] = useState('') // 비회원 구독 이메일
    const [square, setSquare] = useState('') // 평수
    const [bigImg, setBigImg] = useState({ open: false, src: '' }) // 사진 크게보기 여부
    const [token, setToken] = useState(''); // access token
    const [jusoData0, setJusoData0] = useState([]); // api 통해 주소 불러오기 시/도
    const [jusoData1, setJusoData1] = useState([]); // api 통해 주소 불러오기 시/군/구
    const [jusoData2, setJusoData2] = useState([]); // api 통해 주소 불러오기 동/면/읍

    // 초기 진입시 페이지 정보 호출
    useEffect(() => {
        window.scrollTo({ top: 0 })
        getContentDetail(params.contentSeq)
        if (token === '') {
            getAccessToken();
        }
    }, [params.contentSeq])

    useEffect(() => {

        if (params.contentSeq === '6') {
            if (token !== '') {
                getJusoData("");
            }
        }
    }, [token])


    // 컨텐츠 정보 불러오기
    const getContentDetail = async (contentSeq) => {
        try {
            console.log("contentSeq: " + contentSeq)
            const contents = ContentData.data.filter(TargetData =>
                TargetData.contentSeq == contentSeq)
            const content = contents[0]

            // 커스텀 에러 발생시
            if (!content || !!content?.status) {
                navigate('/error', { replace: true })
                return;
            }
            setContentInfo({
                contentSeq: content.contentSeq,
                contentVideo: content.contentVideo || '',
                contentNm: content.contentNm || '',
                contentDetailDescription: content.contentDetailDescription || '',
                contentExplain: content.contentExplain || '',
                contentExplainImg: content.contentExplainImg || undefined,
                contentRes: content.contentRes || '',
                contentResImg: content.contentResImg || '',
                contentKeywordYn: content.contentKeywordYn || 'N',
                contentKeywordDescription: content.contentKeywordDescription || ''
            })
        } catch (error) {
            console.error(error)
            navigate('/error', { replace: true })
        }
    }

    //drop down 주소 정보 불러오기
    const getAccessToken = async () => {
        const consumerKey = "1ae6e0377eec4c988719";
        const consumerSecret = "75942960e1ed4e94a636";
        const requestURL = "https://sgisapi.kostat.go.kr/OpenAPI3/auth/authentication.json?consumer_key=" + consumerKey + "&consumer_secret=" + consumerSecret;
        console.log(requestURL);
        const response = await fetch(requestURL);
        const data = await response.json();
        setToken("?accessToken=" + data.result.accessToken);

    }
    const getJusoData = async (code) => {
        const baseURL = "https://sgisapi.kostat.go.kr/OpenAPI3/addr/stage.json";
        let URL = baseURL + token;
        const strCd = "&cd=" + code;
        let response = "";
        console.log(URL);
        if (code.length > 0 && code.length < 6) {
            response = await fetch(URL + strCd);
        }
        else {
            response = await fetch(URL);
        }
        const data = await response.json();
        const result = data.result;

        if (code.includes("all")) {
            if (code === "all0") {
                setJusoData1([""]);
                setJusoData2([""]);
            }
            else if (code === "all1") {
                setJusoData2([""]);
            }

        }
        else {
            if (code.length > 0) {
                if (code.length === 2) {
                    setJusoData1(result)
                    setJusoData2([""]);
                }
                else if (code.length === 5) {
                    setJusoData2(result)
                }
            }
            else {
                setJusoData0(result);
                setJusoData1([""]);
                setJusoData2([""]);
            }
        }
    }

    // select onchange 이벤트 발생시 
    const [Selected0, setSelected0] = useState(["0", "전체"]); //선택된 value 저장 시/도
    const [Selected1, setSelected1] = useState(["0", "전체"]); //선택된 value 저장 시/군/구
    const [Selected2, setSelected2] = useState(["0", "전체"]); //선택된 value 저장 동/면/읍

    const handleSelect = (e) => {
        const selectOption = e.target.options[e.target.selectedIndex]
        console.log(selectOption.textContent); // 선택된 주소 명칭
        if (e.target.value.includes("all")) {
            if (e.target.value === "all0") {
                setSelected0(["0", "전체"]);
                setSelected1(["0", "전체"]);
                setSelected2(["0", "전체"]);
            }
            else if (e.target.value === "all1") {
                setSelected1(["0", "전체"]);
                setSelected2(["0", "전체"]);
            }
            else if (e.target.value === "all2") {
                setSelected2(["0", "전체"]);
            }
        }
        else {
            if (e.target.value.length === 2) {
                setSelected0([e.target.value, selectOption.textContent]);
                setSelected1(["0", "전체"]);
                setSelected2(["0", "전체"]);
            }
            else if (e.target.value.length === 5) {
                setSelected1([e.target.value, selectOption.textContent]);
                setSelected2(["0", "전체"]);
            }
            else {
                setSelected2([e.target.value, selectOption.textContent]);
            }
        }


        getJusoData(e.target.value);
    }

    // dropdown checkbox 
    const options = [
        { id: 1, label: '아파트', value: '아파트' },
        { id: 2, label: '연립/다세대', value: '연립/다세대' },
        { id: 3, label: '단독/다가구', value: '단독/다가구' },
        { id: 4, label: '오피스텔', value: '오피스텔' }
    ];

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const toggleOption = value => {
        const currentIndex = selectedOptions.indexOf(value);
        const newChecked = [...selectedOptions];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedOptions(newChecked);

    };
    const toggleDropdown = () => setIsOpen(!isOpen);

    // 이미지 error 발생 시 대체 이미지로 이미지 설정
    const onErrorImg = (e) => {
        e.target.src = NormalImg
    }

    return (
        <article className='content'>
            <div className='title-area'>
                <strong className='title'>{contentInfo.contentNm}</strong>
                <p className='description'>{contentInfo.contentDetailDescription}</p>
                {
                    contentInfo.contentVideo !== ""
                    &&
                    <>
                        <p> *RPA를 이용한 업무자동화 이해를 돕기 위한 참고 영상입니다.</p>
                        <iframe className='videoframe' src={contentInfo.contentVideo} />
                    </>

                }
            </div>

            <div className='service-area'>
                {
                    props.isMobile ?
                        <>
                            {/* 모바일 레이아웃 */}
                            <div className='info' style={{ paddingBottom: '4rem' }}>
                                <div className='info-text'>
                                    <strong>서비스 설명</strong>
                                </div>
                                {
                                    contentInfo.contentExplainImg !== undefined
                                    &&
                                    <div className='info-img' style={{ margin: '2rem 0' }} >
                                        <img alt="info-img" src={contentInfo.contentExplainImg} onClick={(e) => { setBigImg({ open: true, src: contentInfo.contentExplainImg }) }} />
                                        <div className='img-background' onClick={(e) => { setBigImg({ open: true, src: contentInfo.contentExplainImg }) }}><p>크게보기</p></div>
                                    </div>
                                }
                                <div className='info-text'>
                                    <p>{contentInfo.contentExplain}</p>
                                </div>
                            </div>
                            {
                                contentInfo.contentVideo === ""
                                &&
                                <div className='result'>
                                    <div className='result-text' >
                                        <strong>서비스 이용하기</strong>
                                    </div>
                                    <div className='result-img' style={{ margin: '2rem 0' }}>
                                        <img alt="result-img" src={contentInfo.contentResImg} onClick={(e) => { setBigImg({ open: true, src: contentInfo.contentResImg }) }} />
                                        <div className='img-background' onClick={(e) => { setBigImg({ open: true, src: contentInfo.contentResImg }) }}><p>크게보기</p></div>
                                    </div>
                                    <div className='result-text'>
                                        <p>{contentInfo.contentRes}</p>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>
                            {/* 테블릿, 데스크탑 레이아웃 */}
                            <div className='info'>
                                {
                                    contentInfo.contentExplainImg !== undefined
                                    &&
                                    <div className='info-img'>
                                        <img alt="info-img" src={contentInfo.contentExplainImg} onError={onErrorImg} />
                                        <div className='img-background' onClick={(e) => { setBigImg({ open: true, src: contentInfo.contentExplainImg }) }}><p>크게보기</p></div>
                                    </div>
                                }
                                <div className='info-text'>
                                    <strong>서비스 설명</strong>
                                    <p>{contentInfo.contentExplain}</p>
                                </div>
                            </div>
                            {
                                contentInfo.contentVideo === ""
                                &&
                                <div className='result'>
                                    <div className='result-text'>
                                        <strong>서비스 이용하기</strong>
                                        <p dangerouslySetInnerHTML={{ __html: contentInfo.contentRes }}></p>
                                    </div>
                                    <div className='result-img'>
                                        <img alt="result-img" src={contentInfo.contentResImg} onError={onErrorImg} />
                                        <div className='img-background' onClick={(e) => { setBigImg({ open: true, src: contentInfo.contentResImg }) }}><p>크게보기</p></div>
                                    </div>
                                </div>
                            }
                        </>
                }
            </div>
            {
                params.contentSeq === "6"
                &&
                <div className='request-area'>
                    {/* <strong>구독하기</strong> */}
                    {
                        <div className='request' >
                            <div>
                                <button className='selectOption' onClick={toggleDropdown}>부동산 유형</button>
                                {isOpen && (
                                    <ul className="dropdown-menu">
                                        {options.map(option => (
                                            <li key={option.id}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOptions.includes(option.value)}
                                                        onChange={() => toggleOption(option.value)}
                                                    />
                                                    {option.label}

                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <select className='거래 유형'>
                                    <option value={'전체'}>전체</option>
                                    <option value={'매매'}>매매</option>
                                    <option value={'전세/월세'}>전세/월세</option>
                                </select>
                                <input
                                    placeholder={`평수를 입력해주세요`}
                                    onChange={(e) => {
                                        setSquare(e.target.value)
                                    }}/>
                            </div>
                            <div>
                                <select className='시/도' onChange={handleSelect} value={Selected0[0]}>
                                    <option
                                        value="all0">시/도 전체
                                    </option>
                                    {
                                        jusoData0.map(juso => (
                                            <option key={juso.cd} value={juso.cd}>{juso.addr_name}</option>
                                        ))
                                    }
                                </select>
                                <select className='시/군/구' onChange={handleSelect} value={Selected1[0]}>
                                    <option
                                        value="all1">시/군/구 전체
                                    </option>
                                    {
                                        jusoData1.map(juso => (
                                            juso !== ""
                                            &&
                                            <option key={juso.cd} value={juso.cd}>{juso.addr_name}</option>
                                        ))
                                    }
                                </select>
                                <select className='동/면/읍' onChange={handleSelect} value={Selected2[0]}>
                                    <option
                                        value="all2">동/면/읍 전체
                                    </option>
                                    {
                                        jusoData2.map(juso => (
                                            juso !== ""
                                            &&
                                            <option key={juso.cd} value={juso.cd}>{juso.addr_name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div>
                                <span>계약일자</span>
                                <span></span>
                            </div>
                            <div>
                                <input className="date" type="date"></input>
                                <input className="date" type="date"></input>
                            </div>

                            {
                                !cookies.sessionUser?.sessionToken &&
                                <div>
                                    <input
                                        className={'input'}
                                        id={'email'}
                                        type='email'
                                        required
                                        placeholder={`결과 받을 이메일`}
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                    />
                                </div>
                            }

                            <button>
                                수행요청
                            </button>
                        </div>
                    }
                </div>
            }
            {
                !!bigImg.open &&
                <div className='big-img'>
                    <div className='big-img-back' style={{ cursor: 'pointer' }} onClick={(e) => { setBigImg({ open: false, src: '' }) }}>
                        <p>X</p>
                    </div>
                    {/* <div className='big-img-area' > */}
                    <img alt="big-img" onError={onErrorImg} src={bigImg.src} />
                    {/* </div> */}
                </div>
            }
            <Footer />
        </article>
    )
}

export default Content